import React from "react";
import { ReactComponent as LandingEllipse } from "../assets/img/landing/landing-ellipse.svg";

import Amazon from "../assets/img/landing/amazon.png";
import Citadel from "../assets/img/landing/citadel.png";
import FlowTraders from "../assets/img/landing/flow-traders.png";
import Goldman from "../assets/img/landing/goldman.png";
import JaneStreet from "../assets/img/landing/jane-street.png";
import JPM from "../assets/img/landing/jpm.png";
import Microsoft from "../assets/img/landing/microsoft.png";
import Millennium from "../assets/img/landing/millennium.png";
import Point72 from "../assets/img/landing/point72.png";
import Sig from "../assets/img/landing/sig.png";

import InfiniteMovingCards from "./InfiniteMovingCards.tsx";
import FAQ from "../routes/about/About.js";

// import { ReactComponent as AcceptedProfile } from "../assets/img/landing/accepted-profile.svg";
// import { ReactComponent as JobListing } from "../assets/img/landing/job-listing.svg";
// import { ReactComponent as RecruitmentPipeline } from "../assets/img/landing/recruitment-pipeline.svg";

class Home extends React.Component {
    render() {
        const fundamentalDetails = [
            {
                name: "Computer Engineering",
                description:
                    "Computer Engineering is an interdisciplinary field that combines electrical engineering and computer science. It focuses on the design and development of computer systems and various digital technologies. Computer engineers work on both hardware and software aspects, often involving embedded systems.",
            },
            {
                name: "Computer Science",
                description:
                    "Computer Science is the study of computers, algorithms, and computational systems. Its key focus areas include software development, algorithms, data structures, AI, networks, and cybersecurity. It's a dynamic field, constantly adapting to new technologies and innovations.",
            },
            {
                name: "Applied Mathematics",
                description:
                    "Applied Mathematics involves using mathematical methods for practical problem solving in various domains like science, engineering, and finance. It's characterized by mathematical modeling, computational techniques, and formulating theories to tackle real-world problems across diverse fields.",
            },
        ];

        return (
            <div
                className="mx-3 flex flex-col gap-y-12"
                style={{
                    fontWeight: 500,
                }}
            >
                {/* section */}
                <div
                    className="rounded-3xl"
                    style={{
                        backgroundColor: "#6589b2",
                        fontFamily: "Roboto",
                    }}
                >
                    <div
                        className="relative flex flex-col items-center justify-center"
                        id="landing"
                    >
                        <div className="items-start justify-start w-full lg:w-1/2 h-full px-10 lg:pr-32 lg:ml-16 lg:mr-auto py-20 lg:py-60 place-items-start pb-60">
                            <h1
                                className="text-5xl font-semibold tracking-wide text-left text-white"
                                id="product"
                            >
                                Your Dream Tech Career Starts Here
                            </h1>
                            <p className="font-light tracking-wider text-white mt-8">
                                Unlock your potential with our tailored courses
                                and hands-on projects, designed to fast-track
                                your success in the tech industry.
                            </p>
                            <div className="flex flex-row lg:flex-col gap-4 lg:gap-16 mt-6 mb-10">
                                <button className="bg-special-blue text-white font-light text-sm rounded-full px-4 py-1.5">
                                    Request a Demo
                                </button>
                                <button
                                    className="bg-special-blue text-white font-light text-sm rounded-full px-4 py-1.5"
                                    onClick={() =>
                                        (window.location.href =
                                            "/account/signup")
                                    }
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                        <div className="relative w-full mx-auto items-center flex lg:flex-none lg:w-full lg:absolute lg:top-0 xl:-top-4">
                            <LandingEllipse className="absolute w-10 lg:w-16 -top-[14.5rem] lg:top-10 right-[8.5rem] lg:right-[26rem]" />
                            <LandingEllipse className="absolute -top-[18.5rem] lg:-top-4 right-[3rem] lg:right-20 w-20 lg:w-28" />
                            <LandingEllipse className="absolute w-20 lg:w-28 -top-[23.5rem] lg:top-96 right-[17rem] lg:right-[29.5rem]" />
                            <LandingEllipse className="absolute -top-[21.5rem] lg:top-80 right-[8.5rem] lg:right-40 w-48 lg:w-96" />
                        </div>
                    </div>
                </div>
                {/* section */}
                <div>
                    <p className="text-center text-xl font-medium">
                        Secure a position at these top companies
                    </p>
                    <main className="relative flex flex-col justify-center overflow-hidden mt-8">
                        <div className="w-full max-w-5xl mx-auto px-4 lg:px-6">
                            <div className="text-center">
                                <div
                                    x-data="{}"
                                    x-init="$nextTick(() => {
                                        let ul = $refs.logos;
                                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                                    })"
                                    className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
                                >
                                    <ul
                                        x-ref="logos"
                                        className="flex items-center justify-center lg:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll bg-special-blue"
                                    >
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Citadel} alt="Citadel logo" className="max-h-full w-auto" />
                                        </li>
                                        {/* <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={FlowTraders} alt="Flow Traders logo" className="max-h-full w-auto" />
                                        </li> */}
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Amazon} alt="Amazon logo" className="max-h-full w-auto" />
                                        </li>
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Goldman} alt="Goldman Sachs logo" className="max-h-full w-auto" />
                                        </li>
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={JaneStreet} alt="Jane Street logo" className="max-h-full w-auto" />
                                        </li>
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={JPM} alt="JPMorgan Chase logo" className="max-h-full w-auto" />
                                        </li>
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Microsoft} alt="Microsoft logo" className="max-h-full w-auto" />
                                        </li>
                                        {/* <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Millennium} alt="Millennium Management logo" className="max-h-full w-auto" />
                                        </li> */}
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Sig} alt="Susquehanna International Group logo" className="max-h-full w-auto" />
                                        </li>
                                        <li className="py-4 h-20 flex items-center justify-center">
                                            <img src={Point72} alt="Point72 logo" className="max-h-full w-auto" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                {/* section */}
                <div id="recruitment">
                    <div
                        className="px-16 rounded-3xl"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL +
                                "/images/magic-gradient.svg"})`,
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div className="w-full lg:w-1/2 pt-20 pb-12 text-left">
                            <p className="mb-4 text-base font-medium text-special-blue">
                                Pursuing Tech, Data, or Quant Careers?
                            </p>
                            <h1
                                className="text-4xl font-semibold tracking-wide !pt-4 !pb-4"
                                id="vetting-process"
                            >
                                We're here to help.
                            </h1>
                            <p className="mb-4 font-normal break-all lg:break-normal">
                                Are you looking to start or advance your
                                technical career? Our platform takes into
                                account your work experience, educational
                                background, and optional knowledge checks
                                through our MIT-based curriculum to find you a
                                job that offers the best possible skills &
                                interest match, compensation, and benefits. We
                                advocate for you throughout your career at
                                no-charge!
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex flex-row w-12/12 lg:w-10/12 p-6 mt-4 ml-auto mr-0 rounded-3xl"
                        style={{
                            backgroundColor: "#7e94a2",
                        }}
                    >
                        <h3 className="text-xl font-medium w-32 mr-[50px] lg:mr-[300px]">
                            Create Your Account
                        </h3>
                        <p
                            className="tracking-wider text-black !pt-0 font-normal break-all lg:break-normal"
                            style={{
                                width: "360px",
                            }}
                        >
                            Sign up and create your profile to access a
                            comprehensive course curriculum crafted by industry
                            experts, tailored to meet the demands of today's
                            tech, data, and quant professionals.
                        </p>
                    </div>
                    <div
                        className="flex flex-row w-11/12 lg:w-9/12 p-6 ml-auto mr-0 -mt-4 rounded-3xl"
                        style={{
                            backgroundColor: "#abb9c4",
                        }}
                    >
                        <h3 className="text-xl font-medium w-32 mr-[50px] lg:mr-[300px]">
                            Assess Your Skills
                        </h3>
                        <p
                            className="tracking-wider text-black !pt-0 font-normal pb-4 break-all lg:break-normal"
                            style={{
                                width: "360px",
                            }}
                        >
                            Take our comprehensive test to evaluate your current
                            knowledge and skills, helping us tailor the best
                            learning path for you.
                        </p>
                    </div>
                    <div
                        className="flex flex-row w-10/12 lg:w-8/12 p-6 ml-auto mr-0 -mt-4 rounded-3xl"
                        style={{
                            backgroundColor: "#d2d7dd",
                        }}
                    >
                        <h3 className="text-xl font-medium w-36 mr-[50px] lg:mr-[300px]">
                            Personalize Your Learning
                        </h3>
                        <p
                            className="tracking-wider text-black !pt-0 font-normal break-all lg:break-normal"
                            style={{
                                width: "360px",
                            }}
                        >
                            Based on your assessment, receive tailored course
                            recommendations from our curriculum designed by
                            industry experts. Engage in self-paced assessments
                            to fit your schedule and preferences.
                        </p>
                    </div>
                    <div
                        className="rounded-3xl w-9/12 lg:w-7/12 ml-auto mr-0 p-6 flex flex-row -mt-4 !pb-4 mb-4"
                        style={{
                            backgroundColor: "#e3e8ec",
                        }}
                    >
                        <h3 className="text-xl font-medium w-36 mr-[50px] lg:mr-[300px]">
                            Advance Your Career
                        </h3>
                        <p
                            className="tracking-wider text-black !pt-0 font-normal"
                            style={{
                                width: "360px",
                            }}
                        >
                            Benefit from personalized recruitment by ex-FAANG
                            Engineers and ex-Quants, who will showcase your
                            expertise to top tech companies and quant firms.
                        </p>
                    </div>
                    <button
                        className="bg-special-blue text-white font-light text-sm rounded-full px-4 py-1.5 ml-auto mr-0 flex"
                        onClick={() =>
                            (window.location.href = "/account/signup")
                        }
                    >
                        Get Started
                    </button>
                </div>
                {/* section */}
                <div
                    className="px-8 lg:px-16 py-8 rounded-3xl"
                    id="education"
                    style={{
                        backgroundColor: "#D2D7DD",
                    }}
                >
                    <div className="lg:grid lg:grid-cols-3 space-y-8 lg:gap-x-10">
                        <div className="flex flex-col lg:gap-y-4 col-span-2">
                            <p className="text-special-blue">The Necessities</p>
                            <h1
                                className="text-3xl font-semibold tracking-wide"
                                id="how-it-works"
                            >
                                Develop your fundamentals.
                            </h1>
                        </div>
                        <div className="flex flex-col gap-y-6">
                            {fundamentalDetails.map((detail) => (
                                <div className="flex flex-col gap-y-4 bg-white rounded-2xl px-4 py-5">
                                    <p
                                        className="text-lg font-semibold uppercase underline"
                                        style={{
                                            color: "#1B4E99",
                                        }}
                                    >
                                        {detail.name}
                                    </p>
                                    <p className="text-base font-normal tracking-wider">
                                        {detail.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* section */}
                <div
                    className="h-[64rem] lg:h-[50rem] w-full bg-grid-black/[0.2] relative flex flex-col mt-16 mb-12"
                    style={{
                        backgroundColor: "white",
                    }}
                >
                    {/* Radial gradient for the container to give a faded look */}
                    <div
                        className="absolute pointer-events-none inset-0 flex items-center justify-center [mask-image:radial-gradient(ellipse_at_center,transparent_45%,black)]"
                        style={{
                            backgroundColor: "white",
                        }}
                    />
                    <div
                        className="text-center flex flex-col gap-y-4 h-min lg:w-[32rem] mt-8 p-4 mx-auto"
                        style={{
                            backgroundColor: "white",
                        }}
                    >
                        <p
                            className="relative z-20 text-xs font-base text-transparent bg-clip-text bg-gradient-to-b"
                            style={{
                                color: "#446EBD",
                            }}
                        >
                            Field-specific courses
                        </p>
                        <p className="relative z-20 text-3xl font-bold text-transparent text-black bg-clip-text bg-gradient-to-b">
                            Master your craft.
                        </p>
                    </div>
                    <div className="grid grid-cols-2 mx-10 lg:mx-0 lg:grid-cols-6 gap-x-6 mt-16 lg:mt-32">
                        {/* with the box background I don't think the shadows look as slick */}
                        {/* <CircleShadow
                            className="absolute w-52"
                            style={{
                                position: "absolute",
                                bottom: "12rem",
                                left: "15.25rem",
                            }}
                        /> */}
                        <div className="hidden lg:block" />
                        <div
                            style={{
                                backgroundColor: "#D2D7DD",
                            }}
                            className="rounded-lg px-4 py-2 h-60 flex flex-col"
                        >
                            <div className="flex-grow" />
                            <p className="font-semibold mt-auto mb-0 float-end text-white">
                                Data Communication Networks
                            </p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#D2D7DD",
                            }}
                            className="rounded-lg px-4 py-2 h-60 flex flex-col mt-20"
                        >
                            <div className="flex-grow" />
                            <p className="font-semibold mt-auto mb-0 float-end text-white">
                                Computational Thinking & Data Science
                            </p>
                            <p className="font-normal text-xs">Mastered</p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#D2D7DD",
                            }}
                            className="rounded-lg px-4 py-2 h-60 flex flex-col"
                        >
                            <div className="flex-grow" />
                            <p className="font-semibold mt-auto mb-0 float-end text-white">
                                Design & Analysis of Algorithms
                            </p>
                            <p className="font-normal text-xs">Recommended</p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#D2D7DD",
                            }}
                            className="rounded-lg px-4 py-2 h-60 flex flex-col mt-20"
                        >
                            <div className="flex-grow" />
                            <p className="font-semibold mt-auto mb-0 float-end text-white">
                                Parallel and Heterogenous Computer Architecture
                            </p>
                            <p className="font-normal text-xs">
                                Need more prerequisites
                            </p>
                        </div>
                        <div className="hidden lg:block" />
                    </div>
                    <button
                        className="mx-auto w-60 text-white font-light text-sm rounded-full px-4 py-1.5 mt-16 opacity-100"
                        style={{
                            backgroundColor: "#1B4E99",
                        }}
                    >
                        Discover More Courses
                    </button>
                </div>
                {/* section */}
                <h1
                    className="text-3xl font-bold tracking-wider text-center -my-4"
                    id="testimonials"
                >
                    Here's what our clients say
                </h1>
                <InfiniteMovingCards
                    items={[
                        {
                            quote:
                                "I love the ability to learn at my own pace and the flexibility of the course schedule. The course content is very well-structured and easy to follow.",
                            name: "John Doe",
                            title: "Software Engineer @ Jane Street",
                        },
                        {
                            quote:
                                "It's a great platform for learning and upskilling. For me, the best part is the hands-on projects that help me apply what I've learned.",
                            name: "Jane Doe",
                            title: "Data Scientist @ Google",
                        },
                        {
                            quote:
                                "My job at Netflix is a dream come true. I owe it all to AlgoLink for helping me prepare for the interview and providing me with the resources I needed to succeed.",
                            name: "Arc Doe",
                            title: "Electrical Engineer @ Netflix",
                        },
                        {
                            quote:
                                "What could be better than this? Ideas elude me. Top-notch stuff!",
                            name: "Ali Doe",
                            title: "Quant @ Goldman",
                        },
                    ]}
                    direction="right"
                    speed="normal"
                    pauseOnHover={true}
                />
                <div
                    className="lg:grid grid-cols-3 gap-x-6 px-0 lg:px-16 mt-12"
                    id="faq"
                >
                    <div className="flex flex-col gap-y-4 text-center lg:text-start">
                        <h1 className="font-medium text-3xl w-full tracking-wider">
                            Do you have more questions?
                        </h1>
                        <p className="font-normal">
                            Contact us at{" "}
                            <a href="mailto:support@algolink.net">
                                support@algolink.net
                            </a>
                        </p>
                    </div>
                    <div className="col-span-2 mt-4 lg:!mt-0 !w-full !mx-0">
                        <FAQ showHeader={false} limit={5} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
